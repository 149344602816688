<template>
  <nav class="back-navigation">
    <a href="#" v-on:click.prevent="$emit('navigateBack')">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
      </svg>
    </a>
  </nav>
</template>

<script>
    export default {
        name: 'BackNavigation'
    };
</script>

<style lang="scss" scoped >
.back-navigation a {
  display: block;
  color: white;
  font-size: 2rem;
  margin: -2rem 2rem 2rem -2rem;

  @media (max-width: 768px) {
    margin: 0 2rem 2rem 0;
  }
}
</style>
