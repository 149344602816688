import { render, staticRenderFns } from "./BackNavigation.vue?vue&type=template&id=483f9229&scoped=true"
import script from "./BackNavigation.vue?vue&type=script&lang=js"
export * from "./BackNavigation.vue?vue&type=script&lang=js"
import style0 from "./BackNavigation.vue?vue&type=style&index=0&id=483f9229&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "483f9229",
  null
  
)

export default component.exports